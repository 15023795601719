/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  faCheckCircle,
  faComments,
  faEllipsisV,
  faExclamationTriangle,
  faSortDown,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { forwardRef, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Accordion,
  AccordionContext,
  Col,
  Dropdown,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
  useAccordionToggle,
  Badge,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import shortid from 'shortid';
import { getSurveyShareALink } from '../../actions/survey.details.action';
import { CHART_TYPES, HIGHCHARTS_TYPES } from '../../constants/charts';
import { parseMLIData } from '../../helpers/charts';
import types from '../../types';
import ExportModal from '../ExportModal';
import RelatedQuestion from '../RelatedQuestion';
import BarChart from './BarChart';
import BarChartUneven from './BarChartUneven';
import ColumnChart from './ColumnChart';
import DonutChart from './DonutChart';
import HeatMapChart from './HeatMapChart';
import RadarChart from './RadarChart';
import WaffleChart from './WaffleChart';

export const ChartMenuCustomToggle = forwardRef(({ children, onClick }, ref) => (
  <span
    className="cursor-pointer px-4"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      onClick(e);
    }}
  >
    {children}
    <FontAwesomeIcon size="lg" className="mt-3" icon={faEllipsisV} />
  </span>
));

ChartMenuCustomToggle.displayName = 'ChartMenuCustomToggle';

export const HeaderFooterMenuCustomToggle = forwardRef(({ children, onClick }, ref) => (
  <span
    className="cursor-pointer"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      onClick(e);
    }}
  >
    {children}
    <FontAwesomeIcon size="lg" className="mt-3" icon={faComments} />
  </span>
));

HeaderFooterMenuCustomToggle.displayName = 'HeaderFooterMenuCustomToggle';
const ChartAccordionToggle = ({ eventKey, callback, displayIdentifier, prompt, onClick }) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = currentEventKey === eventKey;

  const handleDeepOnClick = () => {
    decoratedOnClick();
    if (onClick) {
      onClick();
    }
  };

  return (
    <div className="cursor-pointer d-flex align-items-center mr-5" onClick={handleDeepOnClick}>
      <h3 className="mt-3 mb-3 ml-5 mr-auto">
        <span className="mr-5">{displayIdentifier}</span>
        <span>{prompt}</span>
      </h3>
      <FontAwesomeIcon
        size="lg"
        className="ml-1 mr-5"
        icon={isCurrentEventKey ? faSortUp : faSortDown}
      />
    </div>
  );
};

const Chart = forwardRef(
  (
    {
      memberIdx,
      currentJob,
      visualization,
      currentCategory,
      isRelated,
      updateChartType,
      updateBenchmark,
      questionsCategories,
      shouldScroll,
      onExport,
      spaceAdded = true,
    },
    chartRef
  ) => {
    const dispatch = useDispatch();
    const storeJwt = useSelector((state) => state.session.jwt);
    const { openedQuestions } = useSelector((state) => state.surveyDetails);
    const { sample } = useSelector((state) => state.surveySample);

    const divRefScroll = useRef(null);
    const isOrganizationSurvey = !!currentJob?.queryResult?.organization?.id;
    const [wasChartRendered, setWasChartRendered] = useState(
      openedQuestions.includes(currentJob?.id)
    );
    const [currentChartType, setCurrentChartType] = useState(
      visualization?.details?.chartSettings?.chartSettings[currentJob.id]?.selectedChartType ||
        currentJob?.defaultVisualizationType ||
        ''
    );
    const shouldRenderChart = useMemo(() => {
      return openedQuestions.includes(currentJob?.id) || wasChartRendered;
    }, [wasChartRendered, openedQuestions]);

    const [currentBenchmark, setCurrentBenchmark] = useState(
      visualization?.details?.chartSettings?.chartSettings[currentJob.id]?.benchmarks || {
        national: false,
        state: false,
      }
    );
    const [parsedData, setParsedData] = useState();
    const [checked, setChecked] = useState(false);
    const [isChartLoading, setIsChartLoading] = useState(true);
    const anyGeneralQuestionFilterApplies = useMemo(() => {
      return !!visualization?.details?.filter?.constraintGroups?.length;
    }, [visualization?.details?.filter?.constraintGroups]);

    const anySegmentApplies = useMemo(() => {
      return !!visualization?.details?.segments?.find((segment) => segment.active);
    }, [visualization.details.segments]);

    useEffect(() => {
      if (currentJob && currentCategory) {
        const co = parseMLIData(
          currentJob,
          currentJob?.queryResult,
          currentBenchmark,
          currentCategory,
          visualization?.details?.scheme?.index,
          currentChartType,
          visualization?.details?.enableDefaultSegment
        );
        setParsedData(co);
      }
    }, [currentJob, visualization, currentCategory, currentBenchmark, currentChartType]);

    const handleChartLoaded = () => setIsChartLoading(false);

    const handleAddToReport = (e) => {
      e.stopPropagation();
      e.preventDefault();
      const reportItem = {
        visualizationDetails: visualization?.details,
        visualizationId: visualization?.id,
        contextType: 'QUESTION', // TODO - figure out from where to get this if not a question
        contextIds: [currentJob?.id],
      };
      dispatch({ type: types.reducerTypes.REPORT_MODAL_SHOW, payload: reportItem });
    };

    const handleChangeChart = (e, chartName) => {
      e.stopPropagation();
      e.preventDefault();
      setCurrentChartType(chartName);
      updateChartType(currentJob, chartName);
    };

    const handleBenchmarkChange = (e, newBenchmark) => {
      const benchmark = {
        ...visualization?.details?.chartSettings?.chartSettings[currentJob.id]?.benchmarks,
        ...newBenchmark,
      };
      e.stopPropagation();
      e.preventDefault();
      setCurrentBenchmark(benchmark);
      updateBenchmark(currentJob, benchmark);
    };

    const handleShareALink = (e) => {
      e.stopPropagation();
      e.preventDefault();
      dispatch(
        getSurveyShareALink(visualization?.id, visualization?.contextId, currentJob?.id, storeJwt)
      );
    };

    useEffect(() => {
      if (!divRefScroll.current) return;

      if (shouldScroll && !isChartLoading) {
        divRefScroll.current.scrollIntoView({
          behavior: 'smooth',
        });
      } else if (
        shouldScroll &&
        openedQuestions.includes(currentJob?.id) &&
        openedQuestions.length === 1
      ) {
        divRefScroll.current.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }, [divRefScroll, isChartLoading]);

    const handleExport = (e) => {
      e.stopPropagation();
      e.preventDefault();
      onExport(currentJob.id);
    };

    const renderCurrentSampleSize = () => {
      if (
        visualization?.details?.enableDefaultSegment &&
        Array.isArray(currentJob?.queryResult?.mliDataSegments)
      ) {
        const sampleSizeSegment = currentJob?.queryResult?.mliDataSegments?.find(
          (ds) => ds.name === 'default'
        );
        const splitNumberInterval = sampleSizeSegment?.numberOfRespondentsInterval?.split('-');
        return (
          <>
            <h3>N-Size</h3>
            <div className="pl-4 pt-3">
              {!isOrganizationSurvey &&
                Array.isArray(splitNumberInterval) &&
                Number(splitNumberInterval[0]) < 50 && (
                  <OverlayTrigger
                    trigger={['hover', 'hover']}
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-n-size">
                        Sample may not be representative: use caution when interpreting results.
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon icon={faExclamationTriangle} color="red" className="mr-3" />
                  </OverlayTrigger>
                )}
              {sampleSizeSegment?.numberOfRespondentsInterval}
            </div>
          </>
        );
      }
      return null;
    };

    const defaultActiveKey = useMemo(() => {
      return openedQuestions.includes(currentJob?.id) ? currentJob?.id : null;
    }, [openedQuestions]);

    return (
      <div className="position-relative w-100">
        {Boolean(defaultActiveKey) && (
          <Dropdown className="position-absolute" style={{ right: '30px', top: '40px' }}>
            <Dropdown.Toggle className="w-100 d-flex" as={ChartMenuCustomToggle} />
            <Dropdown.Menu size="sm" title="">
              <Dropdown.Item onClick={handleExport}> Export Question</Dropdown.Item>
              <Dropdown.Item onClick={handleAddToReport}>Add to Report</Dropdown.Item>
              {currentJob?.visualizationTypes?.length > 1 && (
                <Dropdown>
                  <Dropdown.Toggle
                    variant="secondary"
                    className="w-100 d-flex bg-white text-dark border-0 px-4"
                  >
                    Change Chart
                  </Dropdown.Toggle>
                  <Dropdown.Menu size="sm" title="">
                    {currentJob?.visualizationTypes?.map((chartOptions) => (
                      <Dropdown.Item
                        key={shortid.generate()}
                        onClick={(e) => handleChangeChart(e, chartOptions)}
                      >
                        {chartOptions}
                        {currentChartType === chartOptions && (
                          <FontAwesomeIcon className="ml-2" icon={faCheckCircle} />
                        )}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}
              {!anySegmentApplies &&
                (visualization?.details?.filter.constraintGroups?.length || 0) > 0 && (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="secondary"
                      className="w-100 d-flex bg-white text-dark border-0 px-4"
                    >
                      Benchmark
                    </Dropdown.Toggle>
                    <Dropdown.Menu size="sm" title="">
                      <Dropdown.Item
                        onClick={(e) =>
                          handleBenchmarkChange(e, {
                            national: !(currentBenchmark.national || false),
                            state: currentBenchmark.state || false,
                          })
                        }
                      >
                        National{' '}
                        {currentBenchmark.national && (
                          <FontAwesomeIcon className="ml-2" icon={faCheckCircle} />
                        )}
                      </Dropdown.Item>
                      {(visualization?.details?.filter.constraintGroups?.filter(
                        (f) => f.type === 'LOCATION'
                      ).length || 0) > 0 &&
                        (visualization?.details?.filter.constraintGroups?.filter(
                          (f) => f.type === 'QUESTION' || f.type === 'GENERAL'
                        ).length || 0) > 0 && (
                          <Dropdown.Item
                            onClick={(e) =>
                              handleBenchmarkChange(e, {
                                national: currentBenchmark.national || false,
                                state: !(currentBenchmark.state || false),
                              })
                            }
                          >
                            State{' '}
                            {currentBenchmark.state && (
                              <FontAwesomeIcon className="ml-2" icon={faCheckCircle} />
                            )}
                          </Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              <Dropdown.Item onClick={handleShareALink}>Share a link</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
        <Accordion
          className={`w-100 ${spaceAdded ? 'px-5' : ''}`}
          defaultActiveKey={defaultActiveKey}
        >
          <div
            className={`w-100 text-left border-0 bg-gray-200 py-2 d-flex ${
              spaceAdded ? 'mt-5' : 'mt-1'
            }`}
            id={currentJob?.id}
            ref={divRefScroll}
          >
            <div className="w-100">
              <ChartAccordionToggle
                eventKey={currentJob?.id}
                displayIdentifier={currentJob?.displayIdentifier}
                prompt={currentJob?.prompt}
                onClick={() => {
                  setWasChartRendered(true);
                  dispatch({
                    type: types.reducerTypes.SURVEY_DETAILS_TOGGLE_OPEN_QUESTIONS,
                    payload: {
                      toggleQuestionId: currentJob?.id,
                      isInit: false,
                    },
                  });
                }}
              />
            </div>
          </div>
          <Accordion.Collapse eventKey={currentJob?.id}>
            <>
              {parsedData && shouldRenderChart && (
                <Row noGutters className="bg-white">
                  <Col md="10" className="pl-3 pt-3" id={`chart-id-${currentJob.id}`}>
                    {currentChartType === CHART_TYPES.STACKED_BAR && parsedData && (
                      <BarChart
                        parsedData={parsedData}
                        benchmarks={currentBenchmark}
                        qtyOptions={
                          currentJob?.answers?.length || currentJob?.answerOptionsType?.length || 1
                        }
                        chartLoaded={handleChartLoaded}
                        currentJob={currentJob}
                        ref={chartRef}
                      />
                    )}
                    {currentChartType === CHART_TYPES.HORIZONTAL_BAR && parsedData && (
                      <ColumnChart
                        parsedData={parsedData}
                        currentJob={currentJob}
                        chartType={HIGHCHARTS_TYPES.BAR}
                        benchmarks={currentBenchmark}
                        colorScheme={visualization?.details?.scheme?.index}
                        qtyOptions={
                          currentJob?.answers?.length || currentJob?.answerOptionsType?.length || 1
                        }
                        chartLoaded={handleChartLoaded}
                        ref={chartRef}
                      />
                    )}
                    {currentChartType === CHART_TYPES.COLUMN && parsedData && (
                      <ColumnChart
                        parsedData={parsedData}
                        currentJob={currentJob}
                        benchmarks={currentBenchmark}
                        chartLoaded={handleChartLoaded}
                        ref={chartRef}
                        colorScheme={visualization?.details?.scheme?.index}
                        qtyOptions={
                          currentJob?.answers?.length || currentJob?.answerOptionsType?.length || 1
                        }
                      />
                    )}
                    {currentChartType === CHART_TYPES.DONUT && parsedData && (
                      <DonutChart
                        segmented={visualization?.details?.segments?.length > 0}
                        parsedData={parsedData}
                        chartLoaded={handleChartLoaded}
                        ref={chartRef}
                        benchmarks={currentBenchmark}
                      />
                    )}
                    {currentChartType === CHART_TYPES.HEAT_MAP && parsedData && (
                      <HeatMapChart
                        parsedData={parsedData}
                        chartLoaded={handleChartLoaded}
                        ref={chartRef}
                        qtyOptions={
                          currentJob?.answers?.length || currentJob?.answerOptionsType?.length || 1
                        }
                        benchmarks={currentBenchmark}
                        currentJob={currentJob}
                      />
                    )}
                    {currentChartType === CHART_TYPES.RADAR && parsedData && (
                      <RadarChart
                        parsedData={parsedData}
                        chartLoaded={handleChartLoaded}
                        ref={chartRef}
                      />
                    )}
                    {(currentChartType === CHART_TYPES.SPLIT_BAR_POS_NEG ||
                      currentChartType === CHART_TYPES.COMBINED_BAR_POS_NEG) &&
                      parsedData && (
                        <BarChartUneven
                          parsedData={parsedData}
                          chartLoaded={handleChartLoaded}
                          ref={chartRef}
                          benchmarks={currentBenchmark}
                          currentJob={currentJob}
                        />
                      )}
                    {currentChartType === CHART_TYPES.WAFFLE && parsedData && (
                      <WaffleChart
                        parsedData={parsedData}
                        qtyOptions={
                          currentJob?.answers?.length || currentJob?.answerOptionsType?.length || 1
                        }
                        chartLoaded={handleChartLoaded}
                        ref={chartRef}
                        benchmarks={currentBenchmark}
                        scheme={visualization?.details?.scheme}
                      />
                    )}
                  </Col>
                  <Col md="2" className="p-2 d-flex flex-column">
                    <div>
                      <h2 className="border-bottom border-dark w-50 text-uppercase pb-3 mb-3">
                        Sample Size
                      </h2>
                    </div>
                    {renderCurrentSampleSize()}
                    {visualization?.details?.segments?.map((s) => {
                      const sampleSizeSegment = currentJob?.queryResult?.mliDataSegments?.find(
                        (ds) => ds.name === s.label
                      );
                      const splitNumberInterval = sampleSizeSegment?.numberOfRespondentsInterval?.split(
                        '-'
                      );
                      return (
                        <>
                          <h3>N-Size - {sampleSizeSegment?.name}</h3>
                          <div className="pl-4 pt-3">
                            {!isOrganizationSurvey &&
                              Array.isArray(splitNumberInterval) &&
                              Number(splitNumberInterval[0]) < 50 && (
                                <OverlayTrigger
                                  trigger={['hover', 'hover']}
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-n-size">
                                      Sample may not be representative: use caution when
                                      interpreting results.
                                    </Tooltip>
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={faExclamationTriangle}
                                    color="red"
                                    className="mr-3"
                                  />
                                </OverlayTrigger>
                              )}
                            {sampleSizeSegment?.numberOfRespondentsInterval}
                          </div>
                        </>
                      );
                    })}
                    {!spaceAdded && (
                      <div className="mt-3">
                        <h4>Active Filter Type</h4>
                        <Badge pill variant="dark">
                          {sample?.Data?.filterType}
                        </Badge>
                      </div>
                    )}
                    {isRelated && (
                      <div className="switch rel pt-3">
                        <div>
                          {anyGeneralQuestionFilterApplies || anySegmentApplies ? (
                            <OverlayTrigger
                              trigger={['hover', 'hover']}
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-related-question">
                                  Related survey results unavailable
                                </Tooltip>
                              }
                            >
                              <h3 className="related-survey-title rel">
                                {currentJob?.relatedSurveyName}
                              </h3>
                            </OverlayTrigger>
                          ) : (
                            <h3 className="related-survey-title rel">
                              {currentJob?.relatedSurveyName}
                            </h3>
                          )}
                        </div>
                        {isRelated && !anyGeneralQuestionFilterApplies && !anySegmentApplies && (
                          <div className="related-survey-tooltip rel">
                            <Form>
                              <Form.Check
                                type="switch"
                                id={`${currentJob?.id}-${memberIdx}`}
                                onChange={() => {
                                  setChecked(!checked);
                                }}
                              />
                            </Form>
                          </div>
                        )}
                      </div>
                    )}
                  </Col>
                  {checked && isRelated && currentJob?.queryResult?.relatedQuestions && (
                    <RelatedQuestion
                      relatedQuestion={currentJob?.queryResult?.relatedQuestions[0]}
                      relatedSurveyName={currentJob?.relatedSurveyName}
                      visualization={visualization}
                      chartLoaded={handleChartLoaded}
                      ref={chartRef}
                      currentBenchmark={currentBenchmark}
                      currentCategory={questionsCategories?.find(
                        (qc) => qc?.key_id === currentJob?.relatedQuestionId
                      )}
                    />
                  )}
                  <ExportModal />
                </Row>
              )}
              {!parsedData && <div />}
            </>
          </Accordion.Collapse>
        </Accordion>
      </div>
    );
  }
);

Chart.displayName = 'Chart';

export default React.memo(Chart);
