/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { QUESTION_VALUE_TYPE, QUESTION_FILTER_TYPE } from '../../../../constants/common';
import { SurveyEditThemes, SurveyThemeNulled } from '../../../../constants/themes';
import FilterCategory from './filter.category';
import types from '../../../../types';
import {
  updateSurveySampleById,
  getSurveySampleById,
} from '../../../../actions/survey.sample.action';
import './styles.css';

const SurveySTypeQuestionEditForm = ({ handleEditMode }) => {
  const dispatch = useDispatch();
  const storeJwt = useSelector((state) => state.session.jwt);
  const [selectedTheme, setSelectedTheme] = useState(SurveyEditThemes[0]);
  const [questionLabel, setQuestionLabel] = useState('');
  const [questionText, setQuestionText] = useState('');
  const [labels, setLabels] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [filterType, setFilterType] = useState('');
  const { sample, dimensions, sampleUpdated } = useSelector((state) => state.surveySample);
  const { visualizations } = useSelector((state) => state.surveyDetails);

  const onQuestionLabelChange = (e) => {
    setQuestionLabel(e.target.value);
  };
  const onQuestionTextChange = (e) => {
    setQuestionText(e.target.value);
  };

  const onAnswerChange = (e, index) => {
    const answersToUpdate = [...answers];
    answersToUpdate[index].value = e.target.value;
    setAnswers([...answersToUpdate]);
  };

  const onLabelChange = (e, index) => {
    const labelsToUpdate = [...labels];
    labelsToUpdate[index].value = e.target.value;
    setAnswers([...labelsToUpdate]);
  };

  useEffect(() => {
    if (sample) {
      setQuestionLabel(sample.Data.label);
      setQuestionText(sample.Data.text);
      setFilterType(sample.Data.filterType);
    }
    if (dimensions) {
      if (sample?.Data.valueType !== QUESTION_VALUE_TYPE.MULTIPLE) {
        const { allowedValues } = dimensions[0].Data;
        setAnswers(
          Object.keys(allowedValues)
            .filter((key) => key >= 0)
            .map((key) => ({ id: key, value: allowedValues[key] }))
        );
      }
      if (
        [QUESTION_VALUE_TYPE.MULTIPLE, QUESTION_VALUE_TYPE.GRID].includes(sample?.Data.valueType)
      ) {
        const labelList = dimensions.map((dimension) => ({
          id: dimension.Id,
          value: dimension.Data.label,
        }));
        setLabels(labelList);
      }
    }
  }, [sample, dimensions]);

  const updateDimensions = () => {
    answers.forEach((an) => {
      dimensions[0].Data.allowedValues[an.id] = an.value;
    });
    labels.forEach((label, index) => {
      dimensions[index].Data.label = label.value;
    });
  };
  const updateSampleAndDimensions = (theme = null, _filterType = null) => {
    updateDimensions();
    sample.Data.label = questionLabel;
    sample.Data.text = questionText;
    sample.Data.filterType = _filterType || filterType;
    dispatch({
      type: types.reducerTypes.SURVEY_SAMPLE_DIMENSION_GET_SUCCESS,
      payload: {
        sample,
        dimensions,
        sampleTheme: theme,
      },
    });
  };

  const updateVisualizationTheme = (theme) => {
    visualizations.details.scheme = theme;
    dispatch({
      type: types.reducerTypes.SURVEY_DETAILS_VISUALIZATIONS_SUCCESS,
      payload: visualizations,
    });
  };

  const onSave = () => {
    updateDimensions();
    dispatch(
      updateSurveySampleById(
        sample.Id,
        {
          Id: sample.Id,
          Data: {
            label: questionLabel,
            text: questionText,
            filterType,
          },
        },
        dimensions[0],
        storeJwt
      )
    );
  };

  const onChangeTheme = (theme) => {
    setSelectedTheme(theme);
    updateVisualizationTheme(theme);
  };

  const onFilterTypeChange = (e) => {
    setFilterType(e.target.value);
    if (e.target.value === QUESTION_FILTER_TYPE.ATTRIBUTE) {
      updateSampleAndDimensions(SurveyThemeNulled, e.target.value);
    } else {
      updateSampleAndDimensions(selectedTheme, e.target.value);
    }
  };
  useEffect(() => {
    if (!sampleUpdated || !sample) return;
    dispatch(getSurveySampleById(sample.Id, storeJwt));
  }, [sampleUpdated, sample, storeJwt, dispatch]);

  return (
    <Col md="3" className="p-3 survey-sidebar edit-mode">
      <span
        className="d-flex"
        onClick={() => handleEditMode(null, 'sections')}
        onKeyDown={() => handleEditMode(null, 'sections')}
        role="button"
        tabIndex={0}
      >
        <FontAwesomeIcon size="sm" className="mr-3" icon={faChevronLeft} />
        Back to overview
      </span>
      <Col className="info-edit-form">
        {sample?.Data.valueType === QUESTION_VALUE_TYPE.MULTIPLE && (
          <div>
            <h4>Question Text</h4>
            <Form.Control
              as="textarea"
              className="mt-3"
              value={questionText}
              onChange={onQuestionTextChange}
              style={{ height: '100px' }}
              maxLength={500}
              placeholder="Survey Description"
            />
          </div>
        )}
        {sample?.Data.valueType !== QUESTION_VALUE_TYPE.MULTIPLE && (
          <div>
            <h4>Question Text</h4>
            <Form.Control
              as="textarea"
              className="mt-3"
              value={questionLabel}
              onChange={onQuestionLabelChange}
              style={{ height: '100px' }}
              maxLength={500}
              placeholder="Survey Description"
            />
          </div>
        )}
        {sample?.Data.valueType !== QUESTION_VALUE_TYPE.MULTIPLE && (
          <div>
            <h4>Answers</h4>
            {answers.map((answer, index) => {
              return (
                <Form.Control
                  className="mt-3"
                  key={answer.id}
                  id={answer.id}
                  value={answer.value}
                  type="text"
                  onChange={(e) => onAnswerChange(e, index)}
                />
              );
            })}
          </div>
        )}
        {[QUESTION_VALUE_TYPE.MULTIPLE, QUESTION_VALUE_TYPE.GRID].includes(
          sample?.Data.valueType
        ) && (
          <div>
            <h4>Labels</h4>
            {labels.map((label, index) => {
              return (
                <Form.Control
                  className="mt-3"
                  key={label.id}
                  id={label.id}
                  value={label.value}
                  type="text"
                  onChange={(e) => onLabelChange(e, index)}
                />
              );
            })}
          </div>
        )}
        {sample &&
          ![
            QUESTION_VALUE_TYPE.GRID_MULTIPLE,
            QUESTION_VALUE_TYPE.RANK_ORDER,
            QUESTION_VALUE_TYPE.OPEN_TEXT,
          ].includes(sample?.Data.valueType) && (
            <FilterCategory
              filterType={filterType}
              onFilterTypeChange={onFilterTypeChange}
              sample={sample}
            />
          )}
        {filterType !== QUESTION_FILTER_TYPE.ATTRIBUTE && (
          <>
            <h4 className="mb-2">Select Color Scheme</h4>
            {SurveyEditThemes.map((theme) => (
              <div key={`theme-${theme.index}`}>
                <div
                  className={`colors-rounded ${
                    theme.index === selectedTheme?.index ? 'selected' : ''
                  }`}
                  onClick={() => onChangeTheme(theme)}
                  role="button"
                  style={{ display: 'flex' }}
                >
                  {theme.basic.map((color) => (
                    <div
                      role="button"
                      key={`color-${color}`}
                      style={{ backgroundColor: color, flex: 1, height: 20 }}
                    />
                  ))}
                </div>
                <span>{theme.label}</span>
              </div>
            ))}
          </>
        )}
      </Col>
      <Button variant="primary" onClick={onSave} className="btn btn-custom dark mt-3">
        Save
      </Button>
    </Col>
  );
};

SurveySTypeQuestionEditForm.propTypes = {
  handleEditMode: PropTypes.func.isRequired,
};

export default SurveySTypeQuestionEditForm;
