const reducerTypes = {
  // SESSION TYPES
  VALID_TOKEN_SUCCESS: 'VALID_TOKEN_SUCCESS',
  VALID_TOKEN_ERROR: 'VALID_TOKEN_ERROR',
  VALID_TOKEN_CLEAR: 'VALID_TOKEN_CLEAR',
  // BENTO TYPES
  IMPORT_BENTO_PROGRESS: 'IMPORT_BENTO_PROGRESS',
  IMPORT_BENTO_SUCCEDED: 'IMPORT_BENTO_SUCCEDED',
  // MODAL TYPES
  SHOW_MODAL: 'SHOW_MODAL',
  HIDE_MODAL: 'HIDE_MODAL',
  // SURVEYS TYPES
  GET_ALL_SURVEYS: 'GET_ALL_SURVEYS',
  GET_ALL_SURVEYS_ERR: 'GET_ALL_SURVEYS_ERR',
  IS_FETCHING_SURVEYS: 'IS_FETCHING_SURVEYS',
  SURVEY_TITLE_UPDATED: 'SURVEY_TITLE_UPDATED',
  SURVEY_PUBLISHED: 'SURVEY_PUBLISHED',
  SURVEY_UNPUBLISHED: 'SURVEY_UNPUBLISHED',
  DRAFT_SURVEY_CREATED: 'DRAFT_SURVEY_CREATED',
  DRAFT_SURVEY_CONVERTED: 'DRAFT_SURVEY_CONVERTED',
  SHOW_SURVEY_SNACKBAR: 'SHOW_SURVEY_SNACKBAR',
  HIDE_SURVEY_SNACKBAR: 'HIDE_SURVEY_SNACKBAR',
  DRAFT_DELETED: 'DRAFT_DELETED',
  SURVEY_DELETED: 'SURVEY_DELETED',
  DRAFT_SURVEY_ERROR: 'DRAFT_SURVEY_ERROR',
  SURVEY_SPINNER: 'SURVEY_SPINNER',
  // VISUALIZATIONS TYPES
  IS_FETCHING_VISUALIZATIONS: 'IS_FETCHING_VISUALIZATIONS',
  GET_ALL_VISUALIZATIONS: 'GET_ALL_VISUALIZATIONS',
  GET_ALL_VISUALIZATIONS_ERR: 'GET_ALL_VISUALIZATIONS_ERR',
  VISUALIZATIONS_COPY_CREATED: 'VISUALIZATIONS_COPY_CREATED',
  DRAFT_VISUALIZATIONS_ERROR: 'DRAFT_VISUALIZATIONS_ERROR',
  VISUALIZATION_COPY_CONVERTED: 'VISUALIZATION_COPY_CONVERTED',
  VISUALIZATION_COPY_CONVERTED_ERROR: 'VISUALIZATION_COPY_CONVERTED_ERROR',
  VISUALIZATION_DELETED: 'VISUALIZATION_DELETED',
  VISUALIZATION_DELETED_ERROR: 'VISUALIZATION_DELETED_ERROR',
  SHOW_VISUALIZATION_SNACKBAR: 'SHOW_VISUALIZATION_SNACKBAR',
  HIDE_VISUALIZATION_SNACKBAR: 'HIDE_VISUALIZATION_SNACKBAR',
  SELECTED_VISUALIZATION: 'SELECTED_VISUALIZATION',
  IS_FETCHING_VISUALIZATION_METADATA: 'IS_FETCHING_VISUALIZATION_METADATA',
  EXIST_DASHBOARD_DATA: 'EXIST_DASHBOARD_DATA',
  EXIST_DASHBOARD_DATA_ERR: 'EXIST_DASHBOARD_DATA_ERR',
  VISUALIZATION_SPINNER: 'VISUALIZATION_SPINNER',
  // FILEWIZARD
  GET_ALL_METADATA: 'GET_ALL_METADATA',
  GET_ALL_METADATA_ERR: 'GET_ALL_METADATA_ERR',
  GET_SAMPLE_METADATA: 'GET_SAMPLE_METADATA',
  GET_DIMENSION_METADATA: 'GET_DIMENSION_METADATA',
  GET_SAMPLE_METADATA_ERR: 'GET_SAMPLE_METADATA_ERR',
  FILEWIZARD_IS_FETCHING_DATA: 'FILEWIZARD_IS_FETCHING_DATA',
  RESET_FILE_WIZARD: 'RESET_FILE_WIZARD',
  SET_FILE_WIZARD: 'SET_FILE_WIZARD',
  SUM_STEP_FILEWIZARD: 'SUM_STEP_FILEWIZARD',
  DEC_STEP_FILEWIZARD: 'DEC_STEP_FILEWIZARD',
  SELECTED_METADATA: 'SELECTED_METADATA',
  SET_FILEWIZARD_SAMPLES_VALUE: 'SET_FILEWIZARD_SAMPLES_VALUE',
  UPDATE_CHART: 'UPDATE_CHART',
  UPDATE_CHART_ERR: 'UPDATE_CHART_ERR',
  UPDATE_CHART_SUCCESS: 'UPDATE_CHART_SUCCESS',
  SET_VISUALIZATION: 'SET_VISUALIZATION',
  SET_FILE_WIZARD_SELECTED_SAMPLES: 'SET_FILE_WIZARD_SELECTED_SAMPLES',
  SET_FILE_WIZARD_SELECTED_METADATAS: 'SET_FILE_WIZARD_SELECTED_METADATAS',
  SET_FILE_WIZARD_SELECTED_DIMENSIONS: 'SET_FILE_WIZARD_SELECTED_DIMENSIONS',
  RESET_FILE_WIZARD_SELECTED_SAMPLES: 'RESET_FILE_WIZARD_SELECTED_SAMPLES',
  // DASHBOARD
  GET_DASHBOARD_DATA: 'GET_DASHBOARD_DATA',
  GET_DASHBOARD_DATA_ERR: 'GET_DASHBOARD_DATA_ERR',
  IS_FETCHING_DASHBOARDDATA: 'IS_FETCHING_DASHBOARDDATA',
  // VALIDATION TYPES
  SET_AWS_FOLDER: 'SET_AWS_FOLDER',
  SET_AWS_FOLDER_ERR: 'SET_AWS_FOLDER_ERR',
  RAND_S0_IMPORT_TO_BENTO_STATUS: 'RAND_S0_IMPORT_TO_BENTO_STATUS',
  REPLACEDATASET_S0_IMPORT_TO_BENTO_STATUS: 'REPLACEDATASET_S0_IMPORT_TO_BENTO_STATUS',
  VISUALIZATION_S1_BENTO_STATUS: 'VISUALIZATION_S1_BENTO_STATUS',
  RESET_VALIDATIONS: 'RESET_VALIDATIONS',
  // WIZARD VALIDATIONS
  SET_PROVIDER: 'SET_PROVIDER',
  SET_WIZARD: 'SET_WIZARD',
  SUM_STEP: 'SUM_STEP',
  DEC_STEP: 'DEC_STEP',
  SET_FILE_PROGRESS: 'SET_FILE_PROGRESS',
  SET_FILE_SUCCESS: 'SET_FILE_SUCCESS',
  SET_FILE_ERROR: 'SET_FILE_ERROR',
  SET_RELATED_SURVEY_ID: 'SET_RELATED_SURVEY_ID',
  SET_RELATED_ORG_ID: 'SET_RELATED_ORG_ID',
  SELECTED_FILE: 'SELECTED_FILE',
  REMOVE_FILE: 'REMOVE_FILE',
  SET_INPUT_VALUE: 'SET_INPUT_VALUE',
  SET_CHECKBOX_VALUE: 'SET_CHECKBOX_VALUE',
  SET_REPLACE_DATA: 'SET_REPLACE_DATA',
  RESET_FILES: 'RESET_FILES',
  SELECTED_SURVEY: 'SELECTED_SURVEY',
  RESET_WIZARD: 'RESET_WIZARD',
  // ERRORS
  RESET_ALL_ERRORS: 'RESET_ALL_ERRORS',
  NEW_ERROR: 'NEW_ERROR',
  // USERS LIST
  USERS_LIST_SUCCESS: 'USERS_LIST_SUCCESS',
  USERS_LIST_PENDING: 'USERS_LIST_PENDING',
  USERS_LIST_ERROR: 'USERS_LIST_ERROR',
  // USERS LIST
  USERS_BY_EMAIL_LIST_SUCCESS: 'USERS_BY_EMAIL_LIST_SUCCESS',
  USERS_BY_EMAIL_LIST_PENDING: 'USERS_BY_EMAIL_LIST_PENDING',
  USERS_BY_EMAIL_LIST_ERROR: 'USERS_BY_EMAIL_LIST_ERROR',
  // USERS SAVE
  USERS_SAVE_SUCCESS: 'USERS_SAVE_SUCCESS',
  USERS_SAVE_PENDING: 'USERS_SAVE_PENDING',
  USERS_SAVE_ERROR: 'USERS_SAVE_ERROR',
  USERS_SAVE_CLEAR: 'USERS_SAVE_CLEAR',
  // USERS DELETE
  USERS_DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
  USERS_DELETE_PENDING: 'USERS_DELETE_PENDING',
  USERS_DELETE_ERROR: 'USERS_DELETE_ERROR',
  // USERS BATCH
  USERS_BATCH_SUCCESS: 'USERS_BATCH_SUCCESS',
  USERS_BATCH_PENDING: 'USERS_BATCH_PENDING',
  USERS_BATCH_PROGRESS: 'USERS_BATCH_PROGRESS',
  USERS_BATCH_ERROR: 'USERS_BATCH_ERROR',
  USERS_BATCH_RESET: 'USERS_BATCH_RESET',
  // USERS EXPORT
  USERS_EXPORT_SUCCESS: 'USERS_EXPORT_SUCCESS',
  USERS_EXPORT_PENDING: 'USERS_EXPORT_PENDING',
  USERS_EXPORT_ERROR: 'USERS_EXPORT_ERROR',
  // USERS CHECK TOKEN
  CHECK_TOKEN_SUCCESS: 'CHECK_TOKEN_SUCCESS',
  CHECK_TOKEN_PENDING: 'CHECK_TOKEN_PENDING',
  CHECK_TOKEN_ERROR: 'CHECK_TOKEN_ERROR',
  // USERS ASSIGN ORG
  USER_ASSIGN_ORG_SUCCESS: 'USER_ASSIGN_ORG_SUCCESS',
  USER_ASSIGN_ORG_PENDING: 'USER_ASSIGN_ORG_PENDING',
  USER_ASSIGN_ORG_ERROR: 'USER_ASSIGN_ORG_ERROR',
  // SURVEY DETAILS
  SURVEY_DETAILS_CLEAR: 'SURVEY_DETAILS_CLEAR',
  SURVEY_DETAILS_PENDING: 'SURVEY_DETAILS_PENDING',
  SURVEY_DETAILS_SUCCESS: 'SURVEY_DETAILS_SUCCESS',
  SURVEY_DETAILS_ERROR: 'SURVEY_DETAILS_ERROR',
  // SURVEY DETAILS FILTERS
  SURVEY_DETAILS_FILTERS_PENDING: 'SURVEY_DETAILS_FILTERS_PENDING',
  SURVEY_DETAILS_FILTERS_SUCCESS: 'SURVEY_DETAILS_FILTERS_SUCCESS',
  SURVEY_DETAILS_FILTERS_ERROR: 'SURVEY_DETAILS_FILTERS_ERROR',
  // SURVEY DETAILS VISUALIZATIONS
  SURVEY_DETAILS_VISUALIZATIONS_PENDING: 'SURVEY_DETAILS_VISUALIZATIONS_PENDING',
  SURVEY_DETAILS_VISUALIZATIONS_SUCCESS: 'SURVEY_DETAILS_VISUALIZATIONS_SUCCESS',
  SURVEY_DETAILS_VISUALIZATIONS_ERROR: 'SURVEY_DETAILS_VISUALIZATIONS_ERROR',
  // SURVEY DETAILS QUESTIONS_CATEGORIES
  SURVEY_DETAILS_QUESTIONS_CATEGORIES_PENDING: 'SURVEY_DETAILS_QUESTIONS_CATEGORIES_PENDING',
  SURVEY_DETAILS_QUESTIONS_CATEGORIES_SUCCESS: 'SURVEY_DETAILS_QUESTIONS_CATEGORIES_SUCCESS',
  SURVEY_DETAILS_QUESTIONS_CATEGORIES_ERROR: 'SURVEY_DETAILS_QUESTIONS_CATEGORIES_ERROR',
  // SURVEY DETAILS SECTION
  SURVEY_DETAILS_SECTION_PENDING: 'SURVEY_DETAILS_SECTION_PENDING',
  SURVEY_DETAILS_SECTION_SUCCESS: 'SURVEY_DETAILS_SECTION_SUCCESS',
  SURVEY_DETAILS_SECTION_ERROR: 'SURVEY_DETAILS_SECTION_ERROR',
  // SURVEY DETAILS CALCULATE MOE
  SURVEY_DETAILS_CALCULATE_MOE_PENDING: 'SURVEY_DETAILS_CALCULATE_MOE_PENDING',
  SURVEY_DETAILS_CALCULATE_MOE_SUCCESS: 'SURVEY_DETAILS_CALCULATE_MOE_SUCCESS',
  SURVEY_DETAILS_CALCULATE_MOE_ERROR: 'SURVEY_DETAILS_CALCULATE_MOE_ERROR',
  // SURVEY DETAILS JOB
  SURVEY_DETAILS_JOB_PENDING: 'SURVEY_DETAILS_JOB_PENDING',
  SURVEY_DETAILS_JOB_SUCCESS: 'SURVEY_DETAILS_JOB_SUCCESS',
  SURVEY_DETAILS_JOB_ERROR: 'SURVEY_DETAILS_JOB_ERROR',
  // SURVEY DETAILS JOB
  SURVEY_DETAILS_TOGGLE_OPEN_QUESTIONS: 'SURVEY_DETAILS_TOGGLE_OPEN_QUESTIONS',
  SURVEY_DETAILS_CLEAR_OPEN_QUESTIONS: 'SURVEY_DETAILS_CLEAR_OPEN_QUESTIONS',
  SURVEY_DETAILS_TOGGLE_ALL_OPEN_QUESTIONS: 'SURVEY_DETAILS_TOGGLE_ALL_OPEN_QUESTIONS',
  SURVEY_DETAILS_CLEAR_ALL_OPEN_QUESTIONS: 'SURVEY_DETAILS_CLEAR_ALL_OPEN_QUESTIONS',
  // EXPORT
  EXPORT_PENDING: 'EXPORT_PENDING',
  EXPORT_SUCCESS: 'EXPORT_SUCCESS',
  EXPORT_ERROR: 'EXPORT_ERROR',
  EXPORT_MODAL_SHOW: 'EXPORT_MODAL_SHOW',
  EXPORT_MODAL_HIDE: 'EXPORT_MODAL_HIDE',
  // REPORT
  REPORT_LIST_PENDING: 'REPORT_LIST_PENDING',
  REPORT_LIST_SUCCESS: 'REPORT_LIST_SUCCESS',
  REPORT_LIST_ERROR: 'REPORT_LIST_ERROR',
  REPORT_RECENT_LIST_PENDING: 'REPORT_RECENT_LIST_PENDING',
  REPORT_RECENT_LIST_SUCCESS: 'REPORT_RECENT_LIST_SUCCESS',
  REPORT_RECENT_LIST_ERROR: 'REPORT_RECENT_LIST_ERROR',
  REPORT_CREATE_MODAL_SHOW: 'REPORT_CREATE_MODAL_SHOW',
  REPORT_CREATE_MODAL_HIDE: 'REPORT_CREATE_MODAL_HIDE',
  REPORT_CREATE_PENDING: 'REPORT_CREATE_PENDING',
  REPORT_CREATE_SUCCESS: 'REPORT_CREATE_SUCCESS',
  REPORT_CREATE_ERROR: 'REPORT_CREATE_ERROR',
  REPORT_ADD_PENDING: 'REPORT_ADD_PENDING',
  REPORT_ADD_SUCCESS: 'REPORT_ADD_SUCCESS',
  REPORT_ADD_ERROR: 'REPORT_ADD_ERROR',
  REPORT_MODAL_SHOW: 'REPORT_MODAL_SHOW',
  REPORT_MODAL_HIDE: 'REPORT_MODAL_HIDE',
  REPORT_LOADED: 'REPORT_LOADED',
  REPORT_TOAST_HIDE: 'REPORT_TOAST_HIDE',
  REPORT_SHARE_TOAST_HIDE: 'REPORT_SHARE_TOAST_HIDE',
  REPORT_DELETE_PENDING: 'REPORT_DELETE_PENDING',
  REPORT_DELETE_SUCCESS: 'REPORT_DELETE_SUCCESS',
  REPORT_DELETE_ERROR: 'REPORT_DELETE_ERROR',
  REPORT_SHARE_PENDING: 'REPORT_SHARE_PENDING',
  REPORT_SHARE_SUCCESS: 'REPORT_SHARE_SUCCESS',
  REPORT_SHARE_ERROR: 'REPORT_SHARE_ERROR',
  REPORT_COPY_PENDING: 'REPORT_COPY_PENDING',
  REPORT_COPY_SUCCESS: 'REPORT_COPY_SUCCESS',
  REPORT_COPY_ERROR: 'REPORT_COPY_ERROR',
  REPORT_DETAILS_GET_PENDING: 'REPORT_DETAILS_GET_PENDING',
  REPORT_DETAILS_GET_SUCCESS: 'REPORT_DETAILS_GET_SUCCESS',
  REPORT_DETAILS_GET_ERROR: 'REPORT_DETAILS_GET_ERROR',
  REPORT_DETAILS_UPDATE_PENDING: 'REPORT_DETAILS_UPDATE_PENDING',
  REPORT_DETAILS_UPDATE_SUCCESS: 'REPORT_DETAILS_UPDATE_SUCCESS',
  REPORT_DETAILS_UPDATE_ERROR: 'REPORT_DETAILS_UPDATE_ERROR',
  REPORT_ITEM_DELETE_PENDING: 'REPORT_ITEM_DELETE_PENDING',
  REPORT_ITEM_DELETE_SUCCESS: 'REPORT_ITEM_DELETE_SUCCESS',
  REPORT_ITEM_DELETE_ERROR: 'REPORT_ITEM_DELETE_ERROR',
  // SURVEY SUMMARY
  SURVEY_SUMMARY_PENDING: 'SURVEY_SUMMARY_PENDING',
  SURVEY_SUMMARY_SUCCESS: 'SURVEY_SUMMARY_SUCCESS',
  SURVEY_SUMMARY_ERROR: 'SURVEY_SUMMARY_ERROR',
  SURVEY_SUMMARY_INFO_MODAL_SHOW: 'SURVEY_SUMMARY_INFO_MODAL_SHOW',
  SURVEY_SUMMARY_INFO_MODAL_HIDE: 'SURVEY_SUMMARY_INFO_MODAL_HIDE',
  SURVEY_SUMMARY_TYPES_PENDING: 'SURVEY_SUMMARY_TYPES_PENDING',
  SURVEY_SUMMARY_TYPES_SUCCESS: 'SURVEY_SUMMARY_TYPES_SUCCESS',
  SURVEY_SUMMARY_TYPES_ERROR: 'SURVEY_SUMMARY_TYPES_ERROR',
  SURVEY_SUMMARY_HIDE_ERROR_TOAST: 'SURVEY_SUMMARY_HIDE_ERROR_TOAST',
  // SHARE A LINK
  SHARE_A_LINK_PENDING: 'SHARE_A_LINK_PENDING',
  SHARE_A_LINK_SUCCESS: 'SHARE_A_LINK_SUCCESS',
  SHARE_A_LINK_ERROR: 'SHARE_A_LINK_ERROR',
  SHARE_A_LINK_CLEAR: 'SHARE_A_LINK_CLEAR',
  // SHARE A LINK BY TOKEN SUCCESS
  SHARE_A_LINK_BY_TOKEN_SUCCESS: 'SHARE_A_LINK_BY_TOKEN_SUCCESS',
  // DASHBOARD
  DASHBOARD_CLEAR_ALL: 'DASHBOARD_CLEAR_ALL',
  DASHBOARD_PENDING: 'DASHBOARD_PENDING',
  DASHBOARD_SUCCESS: 'DASHBOARD_SUCCESS',
  DASHBOARD_ERROR: 'DASHBOARD_ERROR',
  DASHBOARD_FILTERS_PENDING: 'DASHBOARD_FILTERS_PENDING',
  DASHBOARD_FILTERS_SUCCESS: 'DASHBOARD_FILTERS_SUCCESS',
  DASHBOARD_FILTERS_ERROR: 'DASHBOARD_FILTERS_ERROR',
  DASHBOARD_VISUALIZATIONS_PENDING: 'DASHBOARD_VISUALIZATIONS_PENDING',
  DASHBOARD_VISUALIZATIONS_SUCCESS: 'DASHBOARD_VISUALIZATIONS_SUCCESS',
  DASHBOARD_VISUALIZATIONS_ERROR: 'DASHBOARD_VISUALIZATIONS_ERROR',
  DASHBOARD_MENU_OPTIONS_PENDING: 'DASHBOARD_MENU_OPTIONS_PENDING',
  DASHBOARD_MENU_OPTIONS_SUCCESS: 'DASHBOARD_MENU_OPTIONS_SUCCESS',
  DASHBOARD_MENU_OPTIONS_ERROR: 'DASHBOARD_MENU_OPTIONS_ERROR',
  // ORG LIST
  ORG_LIST_SUCCESS: 'ORG_LIST_SUCCESS',
  ORG_LIST_PENDING: 'ORG_LIST_PENDING',
  ORG_LIST_ERROR: 'ORG_LIST_ERROR',
  // ORG SAVE
  ORG_SAVE_SUCCESS: 'ORG_SAVE_SUCCESS',
  ORG_SAVE_PENDING: 'ORG_SAVE_PENDING',
  ORG_SAVE_ERROR: 'ORG_SAVE_ERROR',
  // ORG DELETE
  ORG_DELETE_SUCCESS: 'ORG_DELETE_SUCCESS',
  ORG_DELETE_PENDING: 'ORG_DELETE_PENDING',
  ORG_DELETE_ERROR: 'ORG_DELETE_ERROR',
  // TOPIC LIST
  TOPIC_LIST_SUCCESS: 'TOPIC_LIST_SUCCESS',
  TOPIC_LIST_PENDING: 'TOPIC_LIST_PENDING',
  TOPIC_LIST_ERROR: 'TOPIC_LIST_ERROR',
  // TOPIC ID LIST
  TOPIC_ID_SUCCESS: 'TOPIC_ID_SUCCESS',
  TOPIC_ID_PENDING: 'TOPIC_ID_PENDING',
  TOPIC_ID_ERROR: 'TOPIC_ID_ERROR',
  // SECTION LIST
  SECTION_LIST_SUCCESS: 'SECTION_LIST_SUCCESS',
  SECTION_LIST_PENDING: 'SECTION_LIST_PENDING',
  SECTION_LIST_ERROR: 'SECTION_LIST_ERROR',
  // SECTION ID LIST
  SECTION_ID_SUCCESS: 'SECTION_ID_SUCCESS',
  SECTION_ID_PENDING: 'SECTION_ID_PENDING',
  SECTION_ID_ERROR: 'SECTION_ID_ERROR',
  // SECTION ID LIST
  SECTION_UPDATE_SUCCESS: 'SECTION_UPDATE_SUCCESS',
  SECTION_UPDATE_PENDING: 'SECTION_UPDATE_PENDING',
  SECTION_UPDATE_ERROR: 'SECTION_UPDATE_ERROR',
  // SECTION CREATE
  SECTION_CREATE_SUCCESS: 'SECTION_CREATE_SUCCESS',
  SECTION_CREATE_PENDING: 'SECTION_CREATE_PENDING',
  SECTION_CREATE_ERROR: 'SECTION_CREATE_ERROR',
  // SURVEY QUESTIONS BY SURVEY ID  AND SECTION
  SURVEY_QUESTIONS_SURVEY_ID_SECTION_SUCCESS: 'SURVEY_QUESTIONS_SURVEY_ID_SECTION_SUCCESS',
  SURVEY_QUESTIONS_SURVEY_ID_SECTION_PENDING: 'SURVEY_QUESTIONS_SURVEY_ID_SECTION_PENDING',
  SURVEY_QUESTIONS_SURVEY_ID_SECTION_ERROR: 'SURVEY_QUESTIONS_SURVEY_ID_SECTION_ERROR',
  // SURVEY QUESTION BY SURVEY ID, SECTION ID AND QUESTION ID
  SURVEY_QUESTIONS_SURVEY_ID_SECTION_ID_QUESTION_ID_SUCCESS:
    'SURVEY_QUESTIONS_SURVEY_ID_SECTION_ID_QUESTION_ID_SUCCESS',
  SURVEY_QUESTIONS_SURVEY_ID_SECTION_ID_QUESTION_ID_PENDING:
    'SURVEY_QUESTIONS_SURVEY_ID_SECTION_ID_QUESTION_ID_PENDING',
  SURVEY_QUESTIONS_SURVEY_ID_SECTION_ID_QUESTION_ID_ERROR:
    'SURVEY_QUESTIONS_SURVEY_ID_SECTION_ID_QUESTION_ID_ERROR',
  // SURVEY UPDATE CUSTOM N SIZE
  SURVEY_UPDATE_CUSTOM_N_SIZE_SUCCESS: 'SURVEY_UPDATE_CUSTOM_N_SIZE_SUCCESS',
  SURVEY_UPDATE_CUSTOM_N_SIZE_PENDING: 'SURVEY_UPDATE_CUSTOM_N_SIZE_PENDING',
  SURVEY_UPDATE_CUSTOM_N_SIZE_ERROR: 'SURVEY_UPDATE_CUSTOM_N_SIZE_ERROR',
  // SURVEY UPDATE THRESHOLD CUSTOM N SIZE
  SURVEY_UPDATE_THRESHOLD_CUSTOM_N_SIZE_SUCCESS: 'SURVEY_UPDATE_THRESHOLD_CUSTOM_N_SIZE_SUCCESS',
  SURVEY_UPDATE_THRESHOLD_CUSTOM_N_SIZE_PENDING: 'SURVEY_UPDATE_THRESHOLD_CUSTOM_N_SIZE_PENDING',
  SURVEY_UPDATE_THRESHOLD_CUSTOM_N_SIZE_ERROR: 'SURVEY_UPDATE_THRESHOLD_CUSTOM_N_SIZE_ERROR',
  // SURVEY UPDATE METADATA
  SURVEY_UPDATE_METADATA_SUCCESS: 'SURVEY_UPDATE_METADATA_SUCCESS',
  SURVEY_UPDATE_METADATA_PENDING: 'SURVEY_UPDATE_METADATA_PENDING',
  SURVEY_UPDATE_METADATA_ERROR: 'SURVEY_UPDATE_METADATA_ERROR',
  // SURVEY CREATE METADATA DESCRIPTION
  SURVEY_CREATE_METADATA_DESCRIPTION: 'SURVEY_CREATE_METADATA_DESCRIPTION',
  SURVEY_CREATE_METADATA_DESCRIPTION_SUCCESS: 'SURVEY_CREATE_METADATA_DESCRIPTION_SUCCESS',
  SURVEY_CREATE_METADATA_DESCRIPTION_PENDING: 'SURVEY_CREATE_METADATA_DESCRIPTION_PENDING',
  SURVEY_CREATE_METADATA_DESCRIPTION_ERROR: 'SURVEY_CREATE_METADATA_DESCRIPTION_ERROR',
  // SURVEY UPDATE METADATA DESCRIPTION
  SURVEY_UPDATE_METADATA_DESCRIPTION: 'SURVEY_UPDATE_METADATA_DESCRIPTION',
  SURVEY_UPDATE_METADATA_DESCRIPTION_SUCCESS: 'SURVEY_UPDATE_METADATA_DESCRIPTION_SUCCESS',
  SURVEY_UPDATE_METADATA_DESCRIPTION_PENDING: 'SURVEY_UPDATE_METADATA_DESCRIPTION_PENDING',
  SURVEY_UPDATE_METADATA_DESCRIPTION_ERROR: 'SURVEY_UPDATE_METADATA_DESCRIPTION_ERROR',
  // SURVEY GET METADATA DESCRIPTION
  SURVEY_GET_METADATA_DESCRIPTION_SUCCESS: 'SURVEY_GET_METADATA_DESCRIPTION_SUCCESS',
  SURVEY_GET_METADATA_DESCRIPTION_PENDING: 'SURVEY_GET_METADATA_DESCRIPTION_PENDING',
  SURVEY_GET_METADATA_DESCRIPTION_ERROR: 'SURVEY_GET_METADATA_DESCRIPTION_ERROR',
  // SURVEY SAMPLE DIMENSION GET
  SURVEY_SAMPLE_DIMENSION_GET_PENDING: 'SURVEY_SAMPLE_DIMENSION_GET_PENDING',
  SURVEY_SAMPLE_DIMENSION_GET_SUCCESS: 'SURVEY_SAMPLE_DIMENSION_GET_SUCCESS',
  SURVEY_SAMPLE_DIMENSION_GET_ERROR: 'SURVEY_SAMPLE_DIMENSION_GET_ERROR',
  // SURVEY SAMPLE DIMENSIONS UPDATE
  SURVEY_SAMPLE_DIMENSION_UPDATE_PENDING: 'SURVEY_SAMPLE_DIMENSION_UPDATE_PENDING',
  SURVEY_SAMPLE_DIMENSION_UPDATE_SUCCESS: 'SURVEY_SAMPLE_DIMENSION_UPDATE_SUCCESS',
  SURVEY_SAMPLE_DIMENSION_UPDATE_ERROR: 'SURVEY_SAMPLE_DIMENSION_UPDATE_ERROR',
};

const modelTypes = {
  sampleMetadata: 'sample-metadata',
  dashboardMetadata: 'dashboard-metadata',
};

const spinnerTypes = {
  publishSurvey: 'publishSurvey',
  createDraftSurvey: 'createDraftSurvey',
  convertDraftIntoSurvey: 'convertDraftIntoSurvey',
  deleteDraftSurvey: 'deleteDraftSurvey',
  copyVisualization: 'copyVisualization',
  convertCopyIntoVisualization: 'convertCopyIntoVisualization',
};

const errorTypes = {
  surveyFetchErr: 'surveyFetchErr',
  visualizationsFetchErr: 'visualizationsFetchErr',
  getAllMetadatasErr: 'getAllMetadatasErr',
  getAllSampleMetadataErr: 'getAllSampleMetadataErr',
  updateChartErr: 'updateChartErr',
};

const email = {
  success: 'success',
  error: 'error',
};

export default {
  reducerTypes,
  modelTypes,
  spinnerTypes,
  errorTypes,
  email,
};
